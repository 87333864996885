window.prefillData = function prefillData() {
  var schoolModalData = $('#school-modal-form').data('values');

  if (!schoolModalData) return;

  $('#school-select-modal input[name="school_type"]').attr('required', false);
  if (schoolModalData.nation) {
    $('#school-select-modal #nation')
      .val(schoolModalData.nation)
      .trigger('change');
  }

  if (schoolModalData.region_id) {
    fill_regions(
      $('#school-select-modal #region_id'),
      schoolModalData.region_id
    );
  }

  if (!schoolModalData.city_id) return;

  $('#school-select-modal .school-type').show();
  $('#school-select-modal .course-name').show();

  $('#city_id')
    .attr('data-id', schoolModalData.city_id)
    .val(schoolModalData.city_name);
  $('#school-select-modal #hidden_city_id').val(schoolModalData.city_id);

  if(schoolModalData.school_type){
    var selectSchoolType = $('#school-select-modal input[name="school_type"]');
    var radio_school_type = '';
    switch (schoolModalData.school_type) {
      case 'public_school':
        radio_school_type = 'public';
        $('#school-select-modal .school-details').show();
        break;
      case 'private_school':
        radio_school_type = 'private';
        $('#school-select-modal .school-details').hide();
        break;
      case 'other_school':
        radio_school_type = 'other';
        $('#school-select-modal .school-details').hide();
        break;
    }
    $('#school-select-modal input[name="school_type"][value="' + radio_school_type + '"]').prop('checked', true);
    $('#school-select-modal input[name="school_type"][value="' + radio_school_type + '"]').click();
    selectSchoolType.attr('required', true);
  }

  // if (!schoolModalData.school_code) return;

  // $('#school-select-modal .school-details').show();
  // fill_schools(
  //   $('#school-modal-form #school_code'),
  //   schoolModalData.city_id,
  //   schoolModalData.school_code
  // );
  // fill_courses($('#school-modal-form #course'), schoolModalData.school_code);
}

window.resetForm = function resetForm() {
  $('#school-select-modal #region_id').val('');
  $('#school-select-modal #city_id').val('');
  $('#school-select-modal #hidden_city_id').val('');
  $('#school-select-modal #school_code').val('').selectpicker('refresh');
  $('#school-select-modal #course').val('');
}

$(function () {

  // Check if the modal is there
  if ($('#school-select-modal').length === 0 || typeof $('#school-select-modal').modal === "undefined") return;

  // Open the modal
  $('#school-select-modal').modal({ backdrop: 'static', keyboard: false });

  // First to second step
  $('#school-modal-step1-submit').on('click', function () {
    $('#school-select-modal .step1').removeClass('active');
    $('#school-select-modal .step2').addClass('active');
    $('#school-select-modal .school-inputs').not('.school-geo-info').hide();

    set_autocomplete_city(
      $('#school-select-modal #city_id'),
      function (_event, ui) {
        var sel = $('#school-modal-form #school_code');
        sel.find('option').remove();
        $('#school-modal-form #course').find('option').remove();
        sel.selectpicker('refresh');
        fill_schools(sel, ui.item.id);
        $('#hidden_city_id').val(ui.item.id);
      }
    );
    prefillData();
  });

  $('#school-select-modal #nation').on('change', function () {
    if ($(this).val() && $(this).val().toLowerCase() === 'np') {      
      $('#school-select-modal .school-inputs').hide();
      $('#school-select-modal #city_id').prop('required', false);
      $('#school-select-modal #hidden_city_id').prop('required', false);
      $('#school-select-modal #region_id').prop('required', false);
      $('#school-select-modal #region_id').prop('required', false);
      $('#school-modal-form #school_code').prop('required', false);
      $('#school-modal-form #course').prop('required', false);
      $('#school-select-modal #school-modal-form').attr('required', false);
      $('#school-select-modal input[name="school_type"]').attr('required', false);
      resetForm();
    } else {
      $('#school-select-modal .school-inputs')
        .not('.school-type')
        .not('.school-details')
        .show();
      $('#school-select-modal #city_id').prop('required', true);
      $('#school-select-modal #hidden_city_id').prop('required', true);
      $('#school-select-modal #region_id').prop('required', true);
      $('#school-modal-form #school_code').prop('required', true);
      $('#school-modal-form #course').prop('required', true);
      $('#school-select-modal input[name="school_type"]').attr('required', true);
    }
  });

  $('#school-select-modal input[name="school_type"]').on('change click', function () {
    $('#school-modal-form #school_code').prop(
      'required',
      $(this).val() === 'public'
    );
    $('#school-modal-form #course').prop(
      'required',
      $(this).val() === 'public'
    );
  });
  
  $('#school-select-modal #region_id').on('change', function () {
    set_autocomplete_city(
      $('#school-select-modal #city_id'),
      function (_event, ui) {
        var sel = $('#school-modal-form #school_code');
        sel.find('option').remove();
        $('#school-modal-form #course').find('option').remove();
        sel.selectpicker('refresh');
        fill_schools(sel, ui.item.id);
        $('#hidden_city_id').val(ui.item.id);
      }
    );
  });

  var schoolModalData = $('#school-modal-form').data('values');
  console.log('schoolModalData', schoolModalData);

  if(schoolModalData) {
    // catch the event when a new option is added to the region select
    // and select the schoolModalData region if present
    if (schoolModalData.region_id) {
      var selectModalRegion = $('#school-select-modal #region_id');
      const observerRegion = new MutationObserver(
          () => { selectModalRegion.trigger('mutated') }
      );
      observerRegion.observe(selectModalRegion[0], { childList: true })
      selectModalRegion.on('mutated', () => {
        if(selectModalRegion.children('option[value=' + schoolModalData.region_id + ']').length > 0) {
          selectModalRegion.val(schoolModalData.region_id);
          // note: do not fire the change even or will reset the other fields
        }
      });
    }

    // catch the event when a new option is added to the schools select
    // and select the schoolModalData school code if present
    if(schoolModalData.school_code) {
        var selectModalSchools = $('#school-modal-form #school_code');
        const observerSchools = new MutationObserver(
          () => { selectModalSchools.trigger('mutated') }
        );
        observerSchools.observe(selectModalSchools[0], { childList: true })
        selectModalSchools.on('mutated', () => {
          if(selectModalSchools.children('option[value=' + schoolModalData.school_code + ']').length > 0) {
            selectModalSchools.val(schoolModalData.school_code);
            selectModalSchools.trigger('change');
          }
        });
    }
    
    // catch the event when a new option is added to the course select
    // and select the schoolModalData course if present
    if(schoolModalData.course_id) {
        var selectModalCourses = $('#school-modal-form #course');
        const observerCourses = new MutationObserver(
          () => { selectModalCourses.trigger('mutated') }
        );
        observerCourses.observe(selectModalCourses[0], { childList: true })
        selectModalCourses.on('mutated', () => {
          if(selectModalCourses.children('option[value=' + schoolModalData.course_id + ']').length > 0) {
            selectModalCourses.val(schoolModalData.course_id);
            selectModalCourses.trigger('change');
          }
        });
    }
  }

});

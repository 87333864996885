
$(document).ready(function () {

    var locale = 'it';
    var available_locales = ['it', 'en', 'fr', 'de', 'es'];
    var window_locale = navigator.language || navigator.userLanguage;
    window_locale = window_locale.substring(0, 2).toLowerCase();
    if (available_locales.includes(window_locale)) {
        locale = window_locale;
    }

    var DatatableLanguageUrl = getDatatableLanguageUrl(locale);
    var ParsleyLocale = locale;

    var default_options = {
        pageLength: 30,
        lengthChange: false,
        dom: 'lrtip',
        bInfo: false,
        scrollX: true,
        buttons: [
            {
                extend: 'csvHtml5',
                text: 'Download CSV',
                className: 'download-button',
                // add date to the csv file name in the format YYYYMMDD with no dash
                title: $(".download-button").data('filename') || `export_bSmartSchools_${new Date().toISOString().slice(0, 10)}`,
            }
        ],
        language: {
            url: DatatableLanguageUrl,
            oPaginate: {
                sFirst: "«",
                sPrevious: "‹",
                sNext: "›",
                sLast: "»"
            }
        }
    }

    // create variable action_options that is default_options with the columns key added
    var action_options = {
        ...default_options,
        columns: [
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: true},
            {name: "Azioni", orderable: false}
        ]
    }

    if ($('#dataTable').length) {
        var options = default_options;
        if ($('#dataTable').attr('data-table-options') == 'action_options') {
            options = action_options;
        }

        var table = $('#dataTable').DataTable(options);
        // show me the value of language.oPaginate option of the table
        console.log(table.settings()[0].oLanguage.oPaginate);

        $('.search-box input').on('keyup', function() {
            table.search(this.value).draw();
        });

        $('.download-button').on('click', function() {
            table.button('.buttons-csv').trigger();
        });

        $('.info-modal-button').on('click', function() {
            $('.info-modal').show();
        });
    }

    // INIT FORM
    var forms = $('.parsley-form');
    window.Parsley.setLocale(ParsleyLocale);
    forms.each(function() {
        var form = $(this);

        if (form.length == 0) {
            return;
        }

        form.parsley();

        var steps = getSteps(form);
        var firstStep = steps.first();
        $('.step-content').hide();
        getStepContent(firstStep).show();
        
        steps.each(function(index, step) {
            var step = $(step);
            var stepNumber = getStepNumber(step);
            var stepNavigation = getStepNavigation(step);
            var stepContent = getStepContent(step);
            var input = getStepInput(step);
            var inputId = input.attr('id');
            var submitButton = getStepSubmitButton(step);
        
            // add event listener to
            stepNavigation.on('click', function() {
                clickableStep(step) ? showStep(step) : null;
            });
        
            input.on('change', function() {
                var isValid = checkStepValidity(step);
                resetAllStepsAfter(step);
                toggleSubmitButton(step, isValid);
            });

            submitButton.on('click', function(event) {
                event.preventDefault();
                clickSubmitButton(step);
            });
        });

        form.find("input:submit[name='commit']").on('click', function(event) {
            event.preventDefault();
            form.submit();
        });
    });
});

function getDatatableLanguageUrl(locale) {
    var language_url = '';
    switch (locale) {
        case 'it':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Italian.json';
            break;
        case 'en':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/English.json';
            break;
        case 'es':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Spanish.json';
            break;
        case 'fr':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/French.json';
            break;
        case 'de':
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/German.json';
            break;
        default:
            language_url = 'https://cdn.datatables.net/plug-ins/1.10.25/i18n/Italian.json';
    }
    return language_url;
}

function clickableStep(step) {
    var htmlContent = step.children('.step-content');
    return checkStepValidity(step) && !step.hasClass('active')
};

function showStep(step) {
    var htmlContent = step.children('.step-content');
    $('.step-content').slideUp(500);
    $('.form-step').removeClass('active');
    getStepContent(step).slideDown(500);
    step.addClass('active');
};

// old manageDisableBtn 
function checkStepValidity(step) {
    var form = getFormFromStep(step);
    var isValid = form.parsley().isValid({group: 'step_' + getStepNumber(step)});
    return isValid;
};

function getValidSteps(form) {
    var steps = getSteps(form);
    steps = steps.filter(function(index, step) {
        return checkStepValidity($(step));
    });
    return steps;
};

function resetAllStepsAfter(step) {
    var form = getFormFromStep(step);
    var steps = getSteps(form);
    var stepNumber = getStepNumber(step);
    steps.each(function(index, checkingStep) {
        var checkingStep = $(checkingStep);
        if (getStepNumber(checkingStep) > stepNumber) {
            var input = getStepInput(checkingStep);
            input.val('');
            toggleSubmitButton(checkingStep, false);
            toggleStepNavigation(checkingStep, false);
        }
    });
};

function toggleSubmitButton(step, isValid) {
    var submitButton = getStepSubmitButton(step);
    submitButton.prop('disabled', !isValid);
};

function clickSubmitButton(step) {
    var form = getFormFromStep(step);
    var validSteps = getValidSteps(form);
    var steps = getSteps(form);

    steps.each(function(index, checkingStep) {
        var checkingStep = $(checkingStep);
        if (jQuery.inArray(checkingStep[0],validSteps) == -1) {
            showStep(checkingStep);
            toggleStepNavigation(checkingStep, true);
            getStepInput(checkingStep).attr('data-remote-filled') == 'true' ? loadOptions(checkingStep) : null;
            return false;
        }
    });
};

function toggleStepNavigation(step, isValid) {
    var stepNavigation = getStepNavigation(step);
    isValid ? stepNavigation.addClass('filled') : stepNavigation.removeClass('filled');
}

function loadOptions(step) {
    getOptions(step).then(function(options) {
        fillOptions(step, options);
    }).catch(function(error) {
        console.error('An error occurred:', error);
    });
}

function fillOptions(step, options) {
    var input = getStepInput(step);
    var firstOption = input.find('option').first();
    input.empty();
    switch (getStepInputType(step)) {
        case 'simple-select':
            fillSimpleSelect(input, options, firstOption);
            break;
        case 'multiple-select':
            fillMultipleSelect(input, options, firstOption);
            break;
        case 'custom-licence-select':
            fillCustomLicenceSelect(input, options);
            break;
    }
}

// simple-select, multiple-select, custom-licence-select
function getStepInputType(step) {
    var input = getStepInput(step);
    return input.attr('data-type');
}

function fillSimpleSelect(input, options, firstOption) {
    input.append(firstOption);
    options.forEach(function(option) {
        input.append('<option value="' + option.value + '">' + option.text + '</option>');
    });
    input.trigger('updated');
}

function fillMultipleSelect(input, options, firstOption) {
    input.append(firstOption);
    options.forEach(function(option) {
        input.append('<option value="' + option.value + '">' + option.text + '</option>');
    });
    input.trigger('updated');
}

function fillCustomLicenceSelect(input, options) {
    var licenceRowsContainer = $('#licence-rows-container');
    licenceRowsContainer.empty();

    options.forEach(function(option) {
        var licenceRowHtml = createLicenceRowHtml(option)
        licenceRowsContainer.append(licenceRowHtml);
    });
    handleCustomLicenceSelect(licenceRowsContainer);
}

function handleCustomLicenceSelect(licenceRowsContainer) {
    var licenceRows =  licenceRowsContainer.children('.licence-row');
    licenceRows.find('.volumes').slideUp(0); // hide all volumes

    licenceRows.each(function(index, licenceRow) {
        var licenceRow = $(licenceRow);

        var isClickable = licenceRow.attr('data-allowed');
        if (!isClickable || isClickable != 'true') { return; }

        // expand section handling
        var expandSection = licenceRow.children('.expand-section');
        var volumes = licenceRow.find('.volumes');
        expandSection.on('click', function() {
            handleExpandSectionClick(expandSection, volumes);
        });

        // licence checkbox handling
        var licenceCheckbox = licenceRow.find('.licence-checkbox');
        licenceCheckbox.on('click', function() {
            handleLicenceCheckboxClick(licenceCheckbox);
        });
        licenceCheckbox.trigger('click');
    });
}

function handleLicenceCheckboxClick(licenceCheckbox) {
    var originalSelect = $('.custom-licence-select');
    var licenceRow = licenceCheckbox.closest('.licence-row');
    var isbn = licenceRow.attr('data-isbn');
    var isChecked = licenceCheckbox.hasClass('checked');
    isChecked ? licenceCheckbox.removeClass('checked') : licenceCheckbox.addClass('checked');
    if (isChecked) {
        originalSelect.find('option[value="' + isbn + '"]').remove();
    } else {
        originalSelect.append('<option value="' + isbn + '"></option>');
    }
    originalSelect.children('option').prop('selected', true);
    originalSelect.trigger('change');
}


function handleExpandSectionClick(expandSection, volumes) {
    volumes.hasClass('open') ? volumes.removeClass('open') : volumes.addClass('open');
    volumes.hasClass('open') ? volumes.slideDown(200) : volumes.slideUp(200);
    // change icon on expand section by changing the d attrubute of the path of the svg from "M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z" to "M7.458 8.1v-.012h1.5V8.1h3.375a.75.75 0 1 1 0 1.5h-.889v-.028H4.557V9.6h-.474a.75.75 0 1 1 0-1.5z"
    var plusIconPath = "M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z";
    var minusIconPath = "M7.458 8.1v-.012h1.5V8.1h3.375a.75.75 0 1 1 0 1.5h-.889v-.028H4.557V9.6h-.474a.75.75 0 1 1 0-1.5z";
    var path = expandSection.children('svg').children('path');
    volumes.hasClass('open') ? path.attr('d', minusIconPath) : path.attr('d', plusIconPath);
}

// getters

function getSteps(form) {
    return form.find('.steps').children('.form-step');
}

function getNextStep(step) {
    var steps = getSteps(getFormFromStep(step));
    var nextStep = steps[getStepNumber(step)];
    return $(nextStep);
}

function getPreviousStep(step) {
    var steps = getSteps(getFormFromStep(step));
    var previousStep = steps[getStepNumber(step) - 2];
    return $(previousStep);
}

function getFormFromStep(step) {
    return step.closest('.parsley-form');
}

function getStepNumber(step) {
    return step.attr('class').split(' ')[1].split('-')[1];
}

function getStepNavigation(step) {
    return step.children('.step-header');
}

function getStepContent(step) {
    return step.children('.step-content');
}

function getStepInput(step) {
    return step.find('select, input:not([type="submit"])');
}

function getStepSubmitButton(step) {
    return step.find('input[type="submit"]');
}

function getSelectedSchoolCode() {
    return $('#organizations_support_teacher_activation_form_school_complex_code, #organizations_substitute_teacher_activation_form_school_complex_code').val();
}

function getSelectedCourseIds() {
    return $('#organizations_support_teacher_activation_form_course_ids, #organizations_substitute_teacher_activation_form_course_ids').val();
}

function getSelectedDisciplineIds() {
    return $('#organizations_support_teacher_activation_form_discipline_ids, #organizations_substitute_teacher_activation_form_discipline_ids').val();
}

function getSelectedLicenceIsbns() {
    return $('#organizations_support_teacher_activation_form_isbns, #organizations_substitute_teacher_activation_form_isbns').val();
}

function getOptions(step) {
    var api_call = getStepInput(step).attr('data-remote-url');
    switch (api_call) {
        case 'course_by_school_code':
            // step course is calling with step school_code as parameter
            return getOptionsStepCourseByScode()
        case 'discipline_by_course':
            // step discipline is calling with step course as parameter
            return getOptionsStepDisciplinebyCourse();
        case 'licences_by_course_and_discipline':
            // step licence is calling with step course and step discipline as parameter
            return getOptionsStepAdoptionByCourseAndDiscipline();
        case 'licences_by_course':
            // step licence is calling with step course as parameter
            return getOptionsStepAdoptionByCourse();
    }
};

// ajax call to get options

function getOptionsStepCourseByScode() {
    var school_code = getSelectedSchoolCode();
    return $.ajax({
        url: '/schools/licences/form_data/courses_with_adoptions_by_school_code/?school_code=' + school_code,
        dataType: 'json'
    });
}

function getOptionsStepDisciplinebyCourse() {
    var course_id = getSelectedCourseIds();
    return $.ajax({
        url: '/schools/licences/form_data/disciplines_with_adoptions_by_course_id/?course_id=' + course_id,
        dataType: 'json'
    });
}

function getOptionsStepAdoptionByCourseAndDiscipline(step) {
    var course_id = getSelectedCourseIds();
    var discipline_id = getSelectedDisciplineIds();
    return $.ajax({
        url: '/schools/licences/form_data/adoptions_by_course_id_and_discipline_id/?course_id=' + course_id + '&discipline_id=' + discipline_id,
        dataType: 'json',
    });
}

function getOptionsStepAdoptionByCourse(step) {
    var course_id = getSelectedCourseIds();
    return $.ajax({
        url: '/schools/licences/form_data/adoptions_by_course_id/?course_id=' + course_id,
        dataType: 'json',
    });
}

function createLicenceRowHtml(option) {
    var volumes_count_text = option.volumes_count > 1 ? $('#volumes-count-text-plural').html() : $('#volumes-count-text-singular').html();
    var volumes = option.volumes.slice(1);
    var user_licences_isbns = ($('#user-licence-isbns').attr('data-user-licences') || '').split(',');
    var user_has_licence = user_licences_isbns.includes(option.isbn);
    var user_has_licence_class = user_has_licence ? 'checked' : '';
    var user_has_licence_message = $('#user-has-licence-message').html();
    var publisher_not_allowed_message = $('#publisher-not-allowed-message').html();
    var tooltip_text = user_has_licence ? user_has_licence_message : publisher_not_allowed_message;
    var licenceRowHtml = `
        <div class="licence-row ${user_has_licence ? 'has-licence' : ''}" data-isbn="${option.isbn}" data-allowed="${option.publisher_allows_activations && !user_has_licence}">
            <div class="licence-checkbox-container">
                <div class="licence-checkbox ${user_has_licence_class}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                        <rect x="1" y="1.641" width="16" height="16" rx="1" fill="none" stroke="#99A1AE" stroke-width="2"/>
                        <path d="m7.929 13.64-3.362-3.284a.775.775 0 0 1 1.082-1.11l2.28 2.222 5.418-5.295a.777.777 0 1 1 1.086 1.112z" fill="#fff"/>
                    </svg>
                </div>
                <div class="volumes-count">
                    ${option.volumes_count} ${volumes_count_text}
                </div>
            </div>
            <div class="expand-section" data-count="${option.volumes_count}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 16" fill="none">
                    <path d="M8.958 4.224a.75.75 0 1 0-1.5 0v3.375H4.083a.75.75 0 1 0 0 1.5h3.375v3.375a.75.75 0 1 0 1.5 0V9.099h3.375a.75.75 0 1 0 0-1.5H8.958z" fill="#1600A8"/>
                </svg>
            </div>
            <div class="not-allowed-message">
                <svg width="16" height="12" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2840_11389)">
                        <path d="M3.50586 9.53252C3.50586 9.11806 3.8393 8.78461 4.25377 8.78461H5.00168V6.79019H4.25377C3.8393 6.79019 3.50586 6.45675 3.50586 6.04228C3.50586 5.62781 3.8393 5.29437 4.25377 5.29437H5.74959C6.16405 5.29437 6.4975 5.62781 6.4975 6.04228V8.78461H6.7468C7.16127 8.78461 7.49471 9.11806 7.49471 9.53252C7.49471 9.94699 7.16127 10.2804 6.7468 10.2804H4.25377C3.8393 10.2804 3.50586 9.94699 3.50586 9.53252Z" fill="#EF3A71"/>
                        <path d="M6.20542 4.00508C6.0184 4.1921 5.76476 4.29716 5.50028 4.29716C5.23581 4.29716 4.98216 4.1921 4.79515 4.00508C4.60814 3.81807 4.50307 3.56442 4.50307 3.29995C4.50307 3.03547 4.60814 2.78182 4.79515 2.59481C4.98216 2.4078 5.23581 2.30273 5.50028 2.30273C5.76476 2.30273 6.0184 2.4078 6.20542 2.59481C6.39243 2.78182 6.4975 3.03547 6.4975 3.29995C6.4975 3.56442 6.39243 3.81807 6.20542 4.00508Z" fill="#EF3A71"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_2840_11389">
                            <rect width="11" height="11" fill="white" transform="translate(0 0.791504)"/>
                        </clipPath>
                    </defs>
                </svg>
                <span class="tooltip-text">${tooltip_text}</span>
            </div>
            <div class="licence-details">
                <div class="book-title">
                    ${option.book_title}
                </div>`;
    if (volumes) {
        licenceRowHtml += `
                <div class="volumes hidden">
        `;
        volumes.forEach(function(volume) {
            licenceRowHtml += `
                    <div class="book-title">
                        ${volume.title}
                    </div>
            `;
        });
        licenceRowHtml += `
                </div>
        `;
    }
    licenceRowHtml += `
                <div class="licence-description">
                    <div class="isbn">
                        ${option.isbn}
                    </div>
                    <div class="publisher">
                        ${option.publisher}
                    </div>
                </div>
            </div>
        </div>  
    `;
    return licenceRowHtml;
}

$(function () {
  // hide fullname if avatar is clicked and show when not clicked
  $('#drop-avatar').change(function() {
    if (this.checked) {
      $('#fullname-header').hide();
    } else {
      $('#fullname-header').show();
    }
  });

  $('.boxed.parent').click(function () {
    $(this).toggleClass('open');
  });

  $('.box-family-member .two-element-flex').click(function () {
    $(this).closest('.box-family-member').toggleClass('open');
  });

  $('.child-name').blur(function (e) {
    var $this = $(this);
    var $error = $this.parent().find('.child-name-error');
    var id = $this.data('id');
    var name = $this.val();

    if (id && name) {
      $.ajax({
        url: '/users/' + id + '/check-children-name?name=' + name,
        method: 'get'
      }).done(function (json) {
        if (json && json.found) {
          $error.show();
        } else {
          $error.hide();
        }
      }).fail(function () {
        $error.hide();
      });
    }
  });
});

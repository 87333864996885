/* CUSTOM SELECT (usando jQuery) */

$(document).ready(function () {

  initializeAllCustomSelect();

  $(".custom-select").on("updated", function () {
    // get all select elements and run the method initializeCustomSelect only on the next select elements
    const $selectElement = $(this);
    const $nextSelect = $selectElement.next(".custom-select");
    initializeCustomSelect($selectElement);
  });
  
  // Funzione principale per inizializzare la custom select
  function initializeAllCustomSelect() {
    $(".custom-select").each(function () {
      initializeCustomSelect($(this));
    });
  }
  
  function initializeCustomSelect($selectElement) {
    $selectElement.addClass("d-none"); // Nasconde l'elemento select originale
    
    // Delete previouse customization for current select
    $selectElement.siblings('.select-selected').remove();
    $selectElement.siblings('.select-items').remove();

    const $selectedDiv = createSelectedDiv($selectElement); // Crea il div che mostra l'opzione selezionata
    const $optionsDiv = createOptionsDiv($selectElement); // Crea il div con tutte le opzioni

    $selectElement.parent().append($selectedDiv); // Aggiunge il div selezionato al DOM
    $selectElement.parent().append($optionsDiv); // Aggiunge il div delle opzioni al DOM

    toggleShowHideDropdown($selectedDiv, $optionsDiv); // Gestisce il click sul div selezionato
  }

  // Gestisce il click sull'elemento selezionato, per aprire/chiudere il menu a tendina
  function toggleShowHideDropdown($selectedDiv, $optionsDiv) {
    $selectedDiv.on("click", function (e) {
      e.stopPropagation(); // Previene che l'evento si propaghi ad altri elementi
      closeAllSelects(this); // Chiude tutti gli altri select aperti
      $optionsDiv.toggleClass("select-hide"); // Mostra/nasconde il menu
      $selectedDiv.toggleClass("select-arrow-active"); // Aggiunge/rimuove la freccia attiva
      $selectedDiv.css("color", "rgba(16, 17, 20, 0.92)"); // Cambia il colore del testo quando è attivo
    });
  }

  // Crea il div che rappresenta l'opzione selezionata
  function createSelectedDiv($selectElement) {
    const $selectedDiv = $("<div>", {
      class: "select-selected " + $selectElement.attr("multiple")
    });

    // Mostra il placeholder se l'opzione selezionata è il placeholder
    if ($selectElement.prop("selectedIndex") === 0 && $selectElement.find("option").eq(0).val() === "") {
      $selectedDiv.html($selectElement.find("option").eq(0).html());
      $selectedDiv.css("color", "rgba(16, 17, 20, 0.40)"); // Colore placeholder
    }

    return $selectedDiv;
  }

  // Crea il div che contiene tutte le opzioni della select
  function createOptionsDiv($selectElement) {
    const $optionsDiv = $("<div>", { class: "select-items select-hide" });
    const $options = $selectElement.find("option").slice(1); // Salta la prima opzione (placeholder)

    // Crea un div per ciascuna opzione
    $options.each(function () {
      const optionDiv = createSelectOptionDiv($(this).html(), $selectElement);
      handleOptionClick(optionDiv, $selectElement); // Gestisce il click sulle opzioni
      $optionsDiv.append(optionDiv);
    });

    return $optionsDiv;
  }

  function createSelectOptionDiv(optionText, $selectElement) {
    var isMultiple = $selectElement.prop("multiple");
    if (isMultiple) {
    var option = `<div class="select-option multiple"><div class="select-option-checkbox"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><rect x="1" y="1.641" width="16" height="16" rx="1" fill="none" stroke="#99A1AE" stroke-width="2"></rect><path d="m7.929 13.64-3.362-3.284a.775.775 0 0 1 1.082-1.11l2.28 2.222 5.418-5.295a.777.777 0 1 1 1.086 1.112z" fill="#fff"></path></svg></div><div class="select-option-text multiple">${optionText}</div></div>`;
    } else {
    var option = `<div class="select-option"><div class="select-option-text">${optionText}</div></div>`;
    }
    return $(option);
  }



  // Gestisce il click al di fuori del select, per chiudere il menu a tendina
  function handleOutsideClick($selectedDiv, $optionsDiv) {
    $(document).on("click", function (e) {
      if (!$(e.target).is($selectedDiv) && !$(e.target).is($optionsDiv)) {
        $optionsDiv.addClass("select-hide");
        $selectedDiv.removeClass("select-arrow-active");
      }
    });
  }

  // Gestisce il click su una singola opzione della select
  function handleOptionClick($optionDiv, $selectElement) {
    $optionDiv.on("click", function () {
      const $selectedDiv = $optionDiv.closest(".select-items").prev(".select-selected");
      const isMultiple = $selectElement.prop("multiple");

      if (isMultiple) {
        handleMultipleSelect($optionDiv, $selectElement, $selectedDiv); // Se è una select multipla
      } else {
        handleSingleSelect($optionDiv, $selectElement, $selectedDiv); // Se è una select singola
      }
    });
  }

  // Gestione della select multipla
  function handleMultipleSelect($optionDiv, $selectElement, $selectedDiv) {
    const clickedOptionText = $optionDiv.find('.select-option-text').html();

    // Attiva/disattiva l'opzione selezionata
    $selectElement.find("option").each(function () {
      if ($(this).html() === clickedOptionText) {
        $(this).prop("selected", !$(this).prop("selected")); // Seleziona/deseleziona
      }
    });

    // Quantità di opzioni selezionate
    var selectedOptionsLength = $selectElement.find("option:selected").length;

    if (selectedOptionsLength > 1) {
        var selectedText = selectedOptionsLength === 2 ? " selezionato" : " selezionati";
      $selectedDiv.html((selectedOptionsLength -1) + selectedText);
    } else {
      $selectedDiv.html($selectElement.find("option").eq(0).html());
    }

    $selectElement.trigger("change"); // Lancia l'evento "change" sulla select

    // Attiva/disattiva lo stile per l'opzione selezionata
    $optionDiv.toggleClass("same-as-selected");
  }

  // Gestione della select singola
  function handleSingleSelect($optionDiv, $selectElement, $selectedDiv) {
    const selectedOptionText = $optionDiv.find(".select-option-text").html();
    // Seleziona l'opzione cliccata
    $selectElement.find("option").each(function () {
      if ($(this).html() === selectedOptionText) {
        $selectElement.prop("selectedIndex", $(this).index());
        $selectedDiv.html(selectedOptionText);
        $selectElement.trigger("change"); // Lancia l'evento "change" sulla select
      }
    });

    // Rimuove la selezione precedente e imposta la nuova
    $optionDiv.siblings(".same-as-selected").removeClass("same-as-selected");
    $optionDiv.addClass("same-as-selected");

    // Chiude il menu dopo la selezione per le select singole
    $selectedDiv.click();
  }

  // Chiude tutti i select personalizzati aperti, tranne quello attuale
  function closeAllSelects(currentElement) {
    $(".select-items").each(function () {
      const $items = $(this);
      if ($items.prev(".select-selected")[0] !== currentElement) {
        $items.addClass("select-hide");
        $items.prev(".select-selected").removeClass("select-arrow-active");
      }
    });
  }

  // Chiude tutti i select se l'utente clicca fuori
  $(document).on("click", function (e) {
    var preventCloseElements = [".select-option.multiple", ".select-option-checkbox"];
    if (!preventCloseElements.some(selector => $(e.target).closest(selector).length)) {
      closeAllSelects();
    }
  });

  // Inizializza la select personalizzata al caricamento della pagina
  initializeAllCustomSelect();

});
